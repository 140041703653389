import Chat, { ChatFormDto } from '@/models/Chat';
import Message, { MessageFormDto } from '@/models/Message';
import { useProfileStore } from '@/store/profile.module';
import axios, { AxiosRequestConfig } from 'axios';
import handleError from './util/ErrorHandler';
import { ServiceError } from './util/ServiceError';
import { Document } from '@/models/Document';
import { ChatDocument } from '@/models/ChatDocument';

let axiosInstance: any;
let axiosInstanceToUploadS3: any;
let storeProfile: any;

class ChatService {
  constructor() {
    axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL,
      headers: {
        'Content-type': 'application/json',
      },
    });
    axiosInstanceToUploadS3 = axios.create({});
  }

  async findChats(): Promise<Chat[] | ServiceError> {
    storeProfile = useProfileStore();
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.get(
        `${process.env.VUE_APP_API_CHAT_SERVICE}/chat?accountId=${storeProfile.accountId}`,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async findUnreadChats(specialistId?: string): Promise<Chat[] | ServiceError> {
    storeProfile = useProfileStore();
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    const options: AxiosRequestConfig = {
      params: {},
    };

    if (specialistId) {
      options.params['specialistId'] = specialistId;
    }

    try {
      const networkResponse = await axiosInstance.get(
        `${process.env.VUE_APP_API_CHAT_SERVICE}/chat/unread?accountId=${storeProfile.accountId}`,
        options,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async createChat(dto: ChatFormDto): Promise<Chat | ServiceError> {
    storeProfile = useProfileStore();
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.post(
        `${process.env.VUE_APP_API_CHAT_SERVICE}/chat?accountId=${storeProfile.accountId}`,
        dto,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async markAsRead(messageIds: string[], chatId: string): Promise<Message[] | ServiceError> {
    storeProfile = useProfileStore();
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.put(
        `${process.env.VUE_APP_API_CHAT_SERVICE}/message/read?accountId=${storeProfile.accountId}&chatId=${chatId}`,
        {
          messageIds: messageIds,
        },
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async findMessages(chatId: string): Promise<Message[] | ServiceError> {
    storeProfile = useProfileStore();
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.get(
        `${process.env.VUE_APP_API_CHAT_SERVICE}/message?accountId=${storeProfile.accountId}&chatId=${chatId}`,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async sendMessage(chatId: string, dto: MessageFormDto): Promise<Message | ServiceError> {
    storeProfile = useProfileStore();
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.post(
        `${process.env.VUE_APP_API_CHAT_SERVICE}/message?accountId=${storeProfile.accountId}&chatId=${chatId}`,
        dto,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async createDocument(chatId: string, messageId: string, document: ChatDocument): Promise<Document | ServiceError> {
    storeProfile = useProfileStore();
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    try {
      const networkResponse = await axiosInstance.post(
        `${process.env.VUE_APP_API_CHAT_SERVICE}/document?accountId=${storeProfile.accountId}&chatId=${chatId}&messageId=${messageId}`,
        document,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async generateURLToUploadToS3(chatId: string, messageId: string): Promise<any | ServiceError> {
    try {
      const networkResponse = await axiosInstance.get(
        `${process.env.VUE_APP_API_CHAT_SERVICE}/document/s3Url?accountId=${storeProfile.accountId}&chatId=${chatId}&messageId=${messageId}`,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async uploadToS3(url: string, file: File): Promise<any | ServiceError> {
    axiosInstanceToUploadS3.defaults.headers['Content-Type'] = file.type;

    try {
      const networkResponse = await axiosInstanceToUploadS3.put(url, file);
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async getContentURLFromS3(chatId: string, messageId: string): Promise<any | ServiceError> {
    storeProfile = useProfileStore();
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    try {
      const networkResponse = await axiosInstance.get(
        `${process.env.VUE_APP_API_CHAT_SERVICE}/document/contentUrlFromS3?accountId=${storeProfile.accountId}&chatId=${chatId}&messageId=${messageId}`,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }
}

export const chatService = new ChatService();
