
import { defineComponent, ref, computed, Ref, onMounted, PropType } from 'vue';
import { PatientDto, updatePatientFromPatientFormDto, Patient, PatientFormDto } from '@/models/Patient';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import { ServiceError } from '@/services/util/ServiceError';
import { patientService } from '@/services/PatientService';
import { createPatientForm, isCreatePatientFormValid, updatePatientForm } from '@/validation/patientForm';
import { userService } from '@/services/UserService';
import { UserRole, UserTableDto } from '@/models/User';
import { useProfileStore } from '@/store/profile.module';
import inputHelper from '@/helpers/Input.helper';

export default defineComponent({
  emits: ['update-patient'],
  props: {
    modify: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    patientList: {
      type: Array as PropType<PatientDto[] | undefined>,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const toast = useToast();
    const { availableLocales } = useI18n();
    const storeProfile = useProfileStore();
    const userIsSpecialist = computed(() => storeProfile.isSpecialist);
    const userProfile = computed(() => storeProfile.getProfile);
    const showDialog = ref(false);
    const showValidation = ref(false);
    const submitting = ref(false);
    const languageOptions = ref(inputHelper.getLangOptions(availableLocales));
    const patient: Ref<Patient> = ref({} as Patient);
    const patientFormDto: Ref<PatientFormDto> = ref({} as PatientFormDto);
    const validatedPatientForm = computed(() =>
      props.modify
        ? updatePatientForm(patientFormDto.value)
        : createPatientForm(patientFormDto.value, props.patientList, ownerSpecialistIds.value),
    );
    const ownerSpecialistIds = ref<string[]>([]);
    const valid = computed(() => isCreatePatientFormValid(validatedPatientForm.value));
    const specialistOptions: Ref<UserTableDto[] | undefined> = ref();
    const disabledProperty = computed(() => {
      if (props.modify) {
        return true;
      }
      if (userIsSpecialist.value && patientFormDto.value.specialistIds) {
        return true;
      }

      return false;
    });
    onMounted(async () => {
      await fillSpecialistDropdown();
    });

    const openDialog = (defaultPatient: PatientDto) => {
      if (!defaultPatient) {
        return;
      }
      showValidation.value = false;
      showDialog.value = true;
      patient.value = defaultPatient;
      patientFormDto.value = new PatientFormDto(defaultPatient);
      ownerSpecialistIds.value = !defaultPatient.specialists
        ? []
        : defaultPatient.specialists
            .filter((s) => {
              return s.permission === 'OWNER';
            })
            .map((s) => s.professionalId);
      if (!props.modify) {
        setDefaultValueInSpecialistDropdown();
      }
    };

    const onSubmit = async () => {
      showValidation.value = true;
      if (!valid.value) {
        return;
      }

      patientFormDto.value.specialistIds = ownerSpecialistIds.value;

      if (props.modify) {
        await modifyPatient(updatePatientFromPatientFormDto(patient.value, patientFormDto.value));
      } else {
        await createPatient(patientFormDto.value);
      }
    };

    async function createPatient(createdPatient: PatientFormDto) {
      submitting.value = true;
      const result = await patientService.create(createdPatient);
      submitting.value = false;
      if (result instanceof ServiceError) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorCreatePatient')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } else {
        toast.add({ severity: 'success', summary: `${t('messages.notifications.successCreatePatient')}`, life: 3000 });
        emit('update-patient');
        showDialog.value = false;
      }
    }

    async function modifyPatient(modifiedPatient: Patient) {
      submitting.value = true;
      const result = await patientService.modify(modifiedPatient.id, modifiedPatient);
      submitting.value = false;
      if (result instanceof ServiceError) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorEditPatient')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } else {
        toast.add({ severity: 'success', summary: `${t('messages.notifications.successEditPatient')}`, life: 3000 });
        emit('update-patient');
        showDialog.value = false;
      }
    }

    async function fillSpecialistDropdown() {
      const result = await userService.findAll([UserRole.SPECIALIST]);

      if (!(result instanceof ServiceError)) {
        specialistOptions.value = result;
      }
    }

    function setDefaultValueInSpecialistDropdown() {
      if (userIsSpecialist.value && userProfile.value) {
        const currentSpecialist: UserTableDto | undefined = specialistOptions.value?.find(
          (specialist: UserTableDto) => specialist.id == userProfile.value?.id,
        );
        if (currentSpecialist && !ownerSpecialistIds.value.length) {
          ownerSpecialistIds.value = [currentSpecialist.professionalId];
        }
      }
    }

    return {
      showValidation,
      validatedPatientForm,
      ownerSpecialistIds,
      submitting,
      patientFormDto,
      showDialog,
      specialistOptions,
      userIsSpecialist,
      languageOptions,
      openDialog,
      onSubmit,
      disableProperty: disabledProperty,
    };
  },
});
