import { MessageType } from './Message';
import User from './User';

export default interface Chat {
  accountId: string;
  userId: string;
  oppositeUserId: string;
  id: string;
  creationDate: Date;
  user: User;
  oppositeUser: User;
  lastMessageDate: Date;
  lastUserIdSendMessage: string;
  lastMessageValue: string;
  lastMessageType: MessageType;

  messagesIdUnreadByUserId: string[];
  messagesIdUnreadByOppositeUserId: string[];
}

class ChatFormDto {
  oppositeUserId: string;

  constructor(oppositeUserId: string) {
    this.oppositeUserId = oppositeUserId;
  }
}

export { Chat, ChatFormDto };
