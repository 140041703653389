import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "l-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_AppHeading = _resolveComponent("AppHeading")!
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_GetRequestError = _resolveComponent("GetRequestError")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_PatientTable = _resolveComponent("PatientTable")!
  const _component_PatientCreateUpdateModal = _resolveComponent("PatientCreateUpdateModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppHeading, {
      title: _ctx.$t(_ctx.route.meta.breadcrumb ? _ctx.route.meta.breadcrumb[0].parent : '')
    }, {
      right: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "p-button p-button-icon",
          onClick: _ctx.onAddPatientDialog
        }, {
          default: _withCtx(() => [
            _cache[0] || (_cache[0] = _createElementVNode("i", {
              class: "icon-plus",
              "aria-hidden": "true"
            }, null, -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('patient.new')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["title"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isLoadingPatients)
        ? (_openBlock(), _createBlock(_component_LoadingComponent, {
            key: 0,
            "spinner-color": "lavender-700"
          }))
        : (_ctx.isPatientsRequestErrorful)
          ? (_openBlock(), _createBlock(_component_GetRequestError, { key: 1 }))
          : (_ctx.isPatientsListEmpty)
            ? (_openBlock(), _createBlock(_component_EmptyState, {
                key: 2,
                heading: _ctx.$t('patients.no-data-title'),
                icon: "icon-alarm",
                text: _ctx.$t('patients.no-data-message'),
                textAtBottom: true
              }, null, 8, ["heading", "text"]))
            : (_openBlock(), _createBlock(_component_PatientTable, {
                key: 3,
                patients: _ctx.patients,
                initFilterBy: _ctx.filter,
                onPatientDetailClick: _ctx.onViewPatient,
                onEditPatientClick: _ctx.onEditPatient,
                onChatCreateClick: _ctx.onCreateChat
              }, null, 8, ["patients", "initFilterBy", "onPatientDetailClick", "onEditPatientClick", "onChatCreateClick"]))
    ]),
    _createVNode(_component_PatientCreateUpdateModal, {
      ref: "patientModalRef",
      modify: _ctx.isEditModal,
      patientList: _ctx.patients,
      onUpdatePatient: _ctx.loadPatientList
    }, null, 8, ["modify", "patientList", "onUpdatePatient"])
  ], 64))
}